export function fetchEditCoinPageData(id: number) {
    const { get } = useOFetchCustom(`coins/${id}/edit`)
    const editCoinData = useEditCoinData()

    async function fetch() {
        return new Promise((resolve, reject) => {
            get(undefined, {
                onSuccess: (response: any) => {
                    editCoinData.value = response
                    resolve(response)
                },
                onError: (error: any) => {
                    reject(error)
                },
            })
        })
    }

    return { fetch, editCoinData }
}

export default defineNuxtRouteMiddleware(async (to, from) => {
    const { fetch } = fetchEditCoinPageData(Number(to.params.id))

    try {
        await fetch()
    } catch (error: any) {
        return navigateTo("/store")
    }
})
